<template>
  <step-lesson-tabs :tabs="tabs" class="module1-part2-lesson"></step-lesson-tabs>
</template>

<script>
import StepLessonTabs from '@/components/training/StepLessonTabs'

export default {
  name: 'Module1Part2Lesson',
  components: { StepLessonTabs },
  data () {
    return {
      tabs: [
        {
          name: 'tab1',
          label: this.$t('module2.part1.tabs.lesson1'),
          component: () => import('@/components/training/module2/part1/Module2Part1Lesson1')
        },
        {
          name: 'tab2',
          label: this.$t('module2.part1.tabs.lesson2'),
          component: () => import('@/components/training/module2/part1/Module2Part1Lesson2')
        },
        {
          name: 'tab3',
          label: this.$t('module2.part1.tabs.lesson3'),
          component: () => import('@/components/training/module2/part1/Module2Part1Lesson3')
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.module2-part1-lesson {}
</style>
